<template>
	
  <div class="container" style="position:relative">
	
	  
    <el-image  :src="url"></el-image>  
	
	
	<div   style="z-index99999;position:absolute;   top:250px;left:680px; background-color:  ; width: 473px;height:150px;">
		
		<div>
			<ul class="ids" style="list-style-type: none;padding:0;">
					
				<li class="active_info"><a href="javascript:void">蓝色</a></li>
				<li><a href="javascript:void">红色</a></li>
				<li><a href="javascript:void">白色</a></li>
				<li><a href="javascript:void">黑色</a></li>
			
			 
			</ul>
		</div> 
		
		<div style="background:  ; width: 100%; height: 30px; margin-top: 73px;">
			<div style="float: left;">
			<span><i @click="dels()" class="el-icon-minus"></i></span>
			&nbsp; 
			<span>
				<input style=" 
				text-align: center;
				border:2px solid #F3F5FB;
				border-radius:5px; 
				width: 70px;  	
				height: 23px;
				background: #F3F5FB;
				" type="text" value="1" id="nums" ></span>&nbsp; 
			<span><i @click="adds()" class="el-icon-plus"></i></span>
			</div>
			
				<div  style="float: right; margin-right: 10px;">
					<a @click="go_pay()" href="javascript:void" style="	float: left;
		margin-left: 10px; 
		border-radius :10px;
		background: red;
		width: 180px;
		height: 30px;
		text-align: center;
		text-decoration:none;
		color: white;
		line-height:2;
		">购买</a>
				</div>
		</div>
	 
	</div>
	
	
	
	
	
	<div    v-if="visibles"     style="z-index99999;position: fixed; top:150px;left:580px; background-color: white; width: 300px;height:300px;">
		
		<p style="text-align: center; margin-top: 20px;" >请扫码支付</p>
		<p style="text-align: center;margin-top: 20px;"><img style="width: 125px;" src="../../assets/images/weixin.png"></p>
 
		<p style="text-align: center;margin-top: 20px;"><a style=" text-decoration:none; color:#3BD1DC"  href="javascript:void(0)" @click="close_pay()"  >关闭</a></p>
		
	</div>
	
	
  </div>
</template>

<script>
	
	import $ from "jquery"
	
	
  export default {
    name: "sj",
    data() {
      return {
        url: require('../../assets/images/ys.jpg'),
		visibles:false
      }
    },
	 created() { 
		 // alert($(".ids li" ).html());
		 
 
		 
		 $(function(){
		 
		    $(".ids li").click(function(){ 
				$(".ids li").eq($(this).index()).addClass("active_info").siblings().removeClass("active_info");
		           // $(".ids li").removeClass(".active_info");
		           // $(this).addClass(".active_info");
		    })
		 })
		 
		 
		 
		 
		 },
	methods: {
		adds(){
				var nums = $("#nums").val();
			 
					nums++;
					$("#nums").val(nums);
			 
		},
		dels(){
			var nums = $("#nums").val();
			if(nums>1){
				nums--;
				$("#nums").val(nums);
			}
		},
		go_pay(){
			
			
			
			this.$router.push({name:"登录页面"})	
			 
		},
		close_pay(){
			this.visibles = false;
		},
	}
	
  }
</script>

<style scoped>
	
	.active_info {  
		    background: red!important;
	}
	.active_info a{   
		    color: white!important;
	}
	
	.ids li a{
		
		text-decoration:none;
		color: #73758F;
		line-height:2;
	
	}
	
	.ids{
		margin: 0;
	}
	
	
	.ids li{
		float: left;
		margin-left: 10px; 
		border-radius :5px;
		background: #F3F5FB;
		width: 100px;
		height: 30px;
		text-align: center;
		
	}
	
		
	  .container {
		width: 1200px;
		margin: auto;
	  }
</style>
